import { Card } from '@mui/material'
import MainLogo from '../MainLogo'
import { PropsWithChildren } from 'react'

const DelistingLayout = ({
  className = '',
  children,
}: PropsWithChildren<{
  className?: string
}>) => {
  return (
    <div className='response-container'>
      <MainLogo />
      <Card className={className}>{children}</Card>
    </div>
  )
}

export default DelistingLayout
