import { IConfiguration } from '@pimy-b2cweb/apiclient-common'
import axios, { AxiosError } from 'axios'
import { store } from '@/stores'
import { selectAuthToken } from '@/stores/auth.selectors'

export const BaseApiConfig: IConfiguration = {
  basePath: process.env.REACT_APP_API_BASE_URL,
}
export const DelistingBaseApiConfig: IConfiguration = {
  basePath: process.env.REACT_APP_DELISTING_API_BASE_URL,
}

const isDev =
  process.env.NODE_ENV === 'development' ||
  ['sit', 'uat'].includes(process.env.REACT_APP_ENV || '')
const eapiHostFromSession = sessionStorage.getItem('eapi-host')

// Scan for injected endpoint from sessionStorage
if (
  isDev &&
  eapiHostFromSession &&
  ((eapiHostFromSession.startsWith('https://') &&
    eapiHostFromSession.endsWith('principal.com.my')) ||
    eapiHostFromSession.match(/^http:\/\/localhost:/))
) {
  BaseApiConfig.basePath = eapiHostFromSession
}

export const apiClient = axios.create({})

apiClient.interceptors.request.use((req) => {
  // For all api request, getting JWT token from redux store
  let token = selectAuthToken(store.getState()) ?? {}
  if (token) {
    if (req.headers) {
      req.headers['Authorization'] = `Bearer ${token}`
    }
  }
  return req
})
apiClient.interceptors.response.use(
  (response) => {
    if (!String(response.status).startsWith('2')) {
      return Promise.reject(response)
    }
    return response
  },
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      // We assume 403 = force logout in portal
    }
    return Promise.reject(error)
  }
)
