import { ReactComponent as InfoIco } from '../../assets/info.svg'
import { CSLink } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'

export interface FundInfoLinkProps {
  url: string
  disabled?: boolean
}

const FundInfoLink = ({ url }: FundInfoLinkProps) => {
  const { t } = useTranslation('fundSelectionPage')

  return (
    <CSLink url={url}>
      <div className='flex flex-row items-center gap-2'>
        <InfoIco />
        {t('fund-info')}
      </div>
    </CSLink>
  )
}

export default FundInfoLink
