import { TextField } from '@mui/material'
import {
  OtpInitReqBodyPayloadIdTypeEnum as idTypeEnum,
  OtpInitReqBodyPayload as LoginFormProps,
} from '@pimy-b2cweb/apiclient-customer-auth'
import { useEffect, useRef, useState } from 'react'
import Recaptcha from 'react-google-recaptcha'
import { useForm, Controller, FieldError } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ID_NUMBER_REGX } from '@pimy-b2cweb/common'
import {
  ButtonWithLoading,
  FormErrorMessage,
  FormLabel,
  Required,
  SelectBoxComp,
  ResponseError,
  useResponseError,
  ResponseErrorProps,
  CSLink,
} from '@pimy-b2cweb/frontend-lib'
import { useMutationOtpInitProps, useMutationOtpInit } from '@/api'
import FormLayout from '@/layout/FormLayout'
import { authSessionSlice } from '@/stores/auth'
import { profileSessionSlice } from '@/stores/profile'
import { getErrorResponseCode, ErrorResponseCodeEnum } from '@/utils'

const idTypes = Object.values(idTypeEnum)
const { sessionPendingOtp, sessionReset } = authSessionSlice.actions
const { sessionResetProfile, sessionUpdateIdNo } = profileSessionSlice.actions

export const LoginPage = () => {
  useEffect(() => {
    //reset all stores on landing
    dispatch(sessionResetProfile())
    dispatch(sessionReset())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    t,
    i18n: { language },
  } = useTranslation(['loginPage', 'idTypes', 'errorResponse', 'common'])

  const captchaRef = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    getValues,
    formState: {
      dirtyFields: { idNo: isIdNoDirty },
    },
  } = useForm<LoginFormProps>({
    mode: 'onChange',
    defaultValues: {
      idType: idTypes[0],
    },
  })

  useEffect(() => {
    if (!!isIdNoDirty) trigger('idNo')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('idType'), isIdNoDirty])

  const [tokenError, setTokenError] = useState<FieldError | undefined>(
    undefined
  )
  const {
    mutate,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationOtpInit()
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const onSubmit = async (data: LoginFormProps) => {
    setIsSubmitting(true)
    //@ts-ignore
    const recaptchaToken = captchaRef.current.getValue() || null

    // console.log(recaptchaToken)
    // setIsSubmitting(false)
    // return

    if (!recaptchaToken) {
      setTokenError({
        type: 'validation-error',
        message: t('recaptcha-error'),
      })
      setIsSubmitting(false)
      return false
    }

    setTokenError(undefined)
    const mutateingData: useMutationOtpInitProps = {
      ...data,
      recaptchaToken,
    }

    mutate(mutateingData)
  }

  const dispatch = useDispatch()
  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg: string = getErrorResponseCode(mutatedError)
      let _errMsg: ResponseErrorProps | undefined = undefined
      switch (errMsg) {
        case ErrorResponseCodeEnum.InvalidRequest:
        case ErrorResponseCodeEnum.UnauthorizedIdNo:
        case ErrorResponseCodeEnum.TooManyAttempts:
        case ErrorResponseCodeEnum.TooManyOtpRequests:
        case ErrorResponseCodeEnum.InvalidMobileNo:
          _errMsg = { i18nKey: `err-${errMsg}` }
          break
        default:
          _errMsg = { i18nKey: errMsg, ns: 'errorResponse' }
          break
      }
      setResponseErrorAttrs(_errMsg)

      //@ts-ignore
      captchaRef.current.reset()
      setIsSubmitting(false)
      return
    }
    setResponseErrorAttrs(undefined)

    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(
        sessionPendingOtp({
          ...mutatedData,
        })
      )
      dispatch(
        sessionUpdateIdNo({
          idNo: getValues('idNo'),
          idType: getValues('idType'),
        })
      )
    }

    if (mutateStatus !== 'loading') {
      setIsSubmitting(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <FormLayout
      className='login-page'
      h1={t('h1')}
      firstP={
        <Trans i18nKey='firstP' t={t} components={{ strong: <strong /> }} />
      }
    >
      {!!responseErrorAttrs && (
        <ResponseError>
          <Trans
            {...responseErrorAttrs}
            t={t}
            components={{ CS: <CSLink /> }}
          />
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='idType'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='idType'
              label={t('id-type')}
              required
              isError={!!error}
            >
              <SelectBoxComp
                id='idType'
                items={idTypes.map((value) => ({
                  label: t(value, { ns: 'idTypes' }),
                  value,
                }))}
                {...field}
                error={error}
                disabled={isSubmitting}
                fullWidth
              />{' '}
            </FormLabel>
          )}
        />
        <Controller
          name='idNo'
          control={control}
          defaultValue=''
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (val: string, formValues: { idType: string }) => {
              let passed = false
              switch (formValues.idType) {
                case idTypeEnum.Nric:
                  passed = ID_NUMBER_REGX.nric.test(val)
                  break
                case idTypeEnum.Arid:
                  passed = ID_NUMBER_REGX.arid.test(val)
                  break
                case idTypeEnum.Oldic:
                  passed = ID_NUMBER_REGX.oldIc.test(val)
                  break
                case idTypeEnum.Psport:
                  passed = ID_NUMBER_REGX.passport.test(val)
                  break
                default:
                  break
              }
              return passed || t('please-enter-a-valid-id-number')
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='idNo'
              label={
                <Trans
                  i18nKey='id-number-*-without-spaces-dashes'
                  t={t}
                  components={{ required: <Required /> }}
                />
              }
              isError={!!error}
            >
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={isSubmitting}
                autoComplete='off'
              />
            </FormLabel>
          )}
        />
        <div className='mt-6 mb-10 flex flex-col'>
          <Recaptcha
            sitekey={process.env.REACT_APP_SITE_KEY || ''}
            ref={captchaRef}
            hl={language}
          />
          <FormErrorMessage error={tokenError} />
        </div>
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          className='mb-10 sm:mb-2'
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          {t('continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
    </FormLayout>
  )
}

export default LoginPage
