import { useTranslation } from 'react-i18next'
import { ErrorResponseCodeEnum } from '@/utils'

export const ResendErrMsg = ({ resendError }: { resendError?: string }) => {
  const { t } = useTranslation(['otpPage', 'errorResponse'])

  if (!resendError) return null

  let _errMsg = ''
  switch (resendError) {
    case ErrorResponseCodeEnum.InvalidRequest:
    case ErrorResponseCodeEnum.InvalidSession:
    case ErrorResponseCodeEnum.TooManyAttempts:
    case ErrorResponseCodeEnum.TooManyOtpRequests:
    case ErrorResponseCodeEnum.CanNotResendOtp:
      _errMsg = t(`err-resend-${resendError}`)
      break

    default:
      _errMsg = t(resendError, { ns: 'errorResponse' })
  }

  return <p className='text-pi-utility-red'>{_errMsg}</p>
}

export default ResendErrMsg
