import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OtpInitReqBodyPayloadIdTypeEnum as idTypeEnum } from '@pimy-b2cweb/apiclient-customer-auth'

export interface ProfileSessionState {
  fullName: string
  idNo: string
  idType?: idTypeEnum
  phoneNumber: string
}

const initialState: ProfileSessionState = {
  fullName: '',
  idNo: '',
  phoneNumber: '',
  idType: undefined,
}

export const profileSessionSlice = createSlice({
  name: 'profileSession',
  initialState,
  reducers: {
    sessionSetProfile: (state, action: PayloadAction<ProfileSessionState>) => {
      state.fullName = action.payload.fullName
      state.idNo = action.payload.idNo
      state.idType = action.payload.idType
      state.phoneNumber = action.payload.phoneNumber
    },
    sessionResetProfile: (state) => {
      state.fullName = initialState.fullName
      state.idNo = initialState.idNo
      state.idType = initialState.idType
      state.phoneNumber = initialState.phoneNumber
    },
    sessionUpdateIdNo: (
      state,
      action: PayloadAction<{
        idNo: string
        idType: idTypeEnum
      }>
    ) => {
      state.idNo = action.payload.idNo
      state.idType = action.payload.idType
    },
  },
})
export default profileSessionSlice.reducer
