import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FundInfoLink from '../FundInfoLink'

export interface FundCardProps {
  name?: string
  isSyariahFund?: boolean
  isNewFund?: boolean
  details?: Array<any>
  fundInfoUrl?: string
  className?: string
  hasSelected?: boolean
  onCardClick?: () => void
  disabled?: boolean
}

const FundCard = ({
  className = '',
  name = '',
  isNewFund,
  isSyariahFund,
  details = [],
  fundInfoUrl = '',
  hasSelected = false,
  onCardClick,
  disabled = false,
}: FundCardProps) => {
  const { t } = useTranslation(['investmentPage', 'common'])

  return (
    <div
      onClick={!disabled ? onCardClick : undefined}
      aria-disabled={disabled}
      className={`flex flex-col justify-between bg-pi-sky-blue-1 rounded-lg border-solid border border-pi-sky-blue-3 outline outline-[3px] outline-transparent transition-outline duration-300 ${
        !!onCardClick ? 'cursor-pointer' : ''
      } ${
        !!disabled ? '!bg-pi-gray-6 !border-pi-gray-3 !text-pi-gray-2' : ''
      } ${hasSelected ? '!outline-pi-principal-blue' : ''} ${className}`}
    >
      <div className='flex flex-col rounded-t-lg p-4'>
        <h1 className='mb-2 md:mb-4'>{name}</h1>
        <div className='flex flex-row flex-wrap gap-2'>
          {isNewFund && (
            <Chip
              className='text-xs !leading-6 h-min font-bold'
              label={t('New', { ns: 'common' })}
              color={!!disabled ? 'default' : 'error'}
            />
          )}
          {isSyariahFund && (
            <Chip
              className='text-xs !leading-6 h-min font-bold'
              label={t('shariah-compliant')}
              color={!!disabled ? 'default' : 'success'}
            />
          )}
        </div>
      </div>
      {!!details?.length && (
        <ul className='mt-0 mb-4 ps-10 pe-4'>
          {details.map(({ index, detail }) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      )}
      <div
        className={`flex flex-row bg-white rounded-b-lg justify-between items-center p-4 border-solid border-0 border-t border-pi-sky-blue-3 ${
          !!disabled ? '!bg-pi-gray-6 !border-pi-gray-3' : ''
        }`}
      >
        <FundInfoLink url={fundInfoUrl} disabled={disabled} />
      </div>
    </div>
  )
}

export default FundCard
