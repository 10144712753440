import { Card } from '@mui/material'
import { PropsWithChildren } from 'react'
import MainLogo from '@/layout/MainLogo'

export const FormLayout = ({
  className = '',
  h1,
  firstP,
  children = null,
}: PropsWithChildren<{
  className?: string
  h1: string | JSX.Element
  firstP: string | JSX.Element
}>) => (
  <Card className={`form-layout response-container sm:p-10 ${className}`}>
    <MainLogo />
    <h1>{h1}</h1>
    <p>{firstP}</p>
    {children}
  </Card>
)

export default FormLayout
