import {
  useMutationSubmitQuestionnaire,
  useQueryGetCurrentQuestionnaire,
} from '@/api'
import LoadingPage from '@/pages/Loading'
import { selectProfileSessionState } from '@/stores/profile.selectors'
import { RiskProfileQuestionnaireSubmitReqDto } from '@pimy-b2cweb/apiclient-customer-stmt'
import {
  ButtonWithLoading,
  FormLabel,
  RadioGroupComp,
  ResponseError,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export interface QuestionnaireFormProps {
  [key: string]: string
}

export interface ISAFProps {
  goBackToFundSelection: () => void
}

const ISAF = ({ goBackToFundSelection }: ISAFProps) => {
  const { t } = useTranslation(['isafPage', 'common'])
  const { idType = '', idNo } = useSelector(selectProfileSessionState)

  const { isLoading, data: questionnaire } = useQueryGetCurrentQuestionnaire()

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSubmitQuestionnaire()

  const { control, handleSubmit } = useForm<QuestionnaireFormProps>({
    mode: 'onChange',
  })

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  useEffect(() => {
    if (mutateStatus === 'error') {
      setResponseErrorAttrs({
        i18nKey: (mutatedError as Error)?.message,
      })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log(mutatedData)
      goBackToFundSelection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutatedData, mutateStatus, mutatedError])

  const onSubmit = (data: QuestionnaireFormProps) => {
    const mutatingData: RiskProfileQuestionnaireSubmitReqDto = {
      questionGroup: questionnaire?.questionGroup.toString() as string,
      answers: Object.entries(data).map(([questionCode, optionCode]) => {
        return { questionCode, optionCode }
      }),
      idType,
      idNo,
    }
    mutate(mutatingData)
  }

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <h1 className='leading-7 mb-6'>{t('what-type-investor')}</h1>
          {!!responseErrorAttrs && (
            <ResponseError>
              {t(responseErrorAttrs.i18nKey, {
                ns: responseErrorAttrs.ns,
              })}
            </ResponseError>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            {questionnaire?.questions?.map(({ code, options, text }) => (
              <Controller
                key={code}
                name={code}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Required',
                  },
                }}
                defaultValue=''
                render={({ field, fieldState: { error } }) => (
                  <FormLabel
                    id={code}
                    label={
                      <span className='whitespace-normal text-sm'>{text}</span>
                    }
                    isError={!!error}
                    className='mb-6'
                  >
                    <RadioGroupComp
                      {...field}
                      items={options.map(({ code, text }) => {
                        return { label: text, key: code, value: code }
                      })}
                      error={error}
                    />
                  </FormLabel>
                )}
              />
            ))}

            <ButtonWithLoading
              type='submit'
              fullWidth
              variant='contained'
              size='large'
              disabled={mutateIsLoading}
              isLoading={mutateIsLoading}
            >
              {t('continue', { ns: 'common' })}
            </ButtonWithLoading>
          </form>
        </>
      )}
    </>
  )
}

export default ISAF
