const useSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search)

  const removeSearchParams = () => {
    window.history.pushState(
      {},
      document.title,
      `${window.location.origin}${window.location.pathname}`
    )
  }

  return { searchParams, removeSearchParams }
}

export default useSearchParams
