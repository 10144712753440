import { ActionModal } from '@pimy-b2cweb/frontend-lib'
import { memo, useEffect, useState } from 'react'
import useCountDown from 'react-countdown-hook'
import { useTranslation, Trans } from 'react-i18next'

const REDIRECT_COUNTDOWN = 5000 //5 sec
export const INTERVAL = 1000 // interval to change

export const HomePageRedirectModal = memo(({ open }: { open: boolean }) => {
  const { t } = useTranslation('feedbackPage')
  const goToPrincipalHomepage = () =>
    window.open(
      'https://www.principal.com.my/',
      '_self',
      'noopener noreferrer nofollow'
    )
  const [redirectTimeLeft, { start }] = useCountDown(
    REDIRECT_COUNTDOWN,
    INTERVAL
  )
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!!open) {
      start()
      setInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (!!initialized && redirectTimeLeft <= 0) goToPrincipalHomepage()
  }, [redirectTimeLeft, initialized])

  return (
    <ActionModal
      title={t('thank-you')}
      actions={[
        {
          label: t('back-to-homepage'),
          onClick: goToPrincipalHomepage,
          variant: 'contained',
        },
      ]}
      open={open}
      actionsStyle='full'
    >
      <Trans
        i18nKey='redirect-modal-text'
        t={t}
        values={{ sec: Math.round(redirectTimeLeft / 1000) }}
        components={{ P: <p />, B: <strong /> }}
      />
    </ActionModal>
  )
})

export default HomePageRedirectModal
