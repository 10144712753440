import { useMutation } from '@tanstack/react-query'
import { SessionApi as SessionApiClass } from '@pimy-b2cweb/apiclient-customer-auth'
// import { store } from '@/stores'
// import { selectAuthToken } from '@/stores/auth.selectors'
import { BaseApiConfig, apiClient } from './api-config'

export const sessionApi = new SessionApiClass(BaseApiConfig, apiClient)

export const useMutationExtendToken = () => {
  // let token = selectAuthToken(store.getState()) ?? {}
  const mutationReturns = useMutation({
    mutationFn: async () => {
      const {
        data: { body },
      } = await sessionApi
        .extendToken
        //   {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
        ()
      return body
    },
    mutationKey: ['mutation-session-extend-token'],
  })
  return mutationReturns
}
