import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  EpfDelistedApi,
  EpfDelistedFundConsent,
  EpfDelistedFundFeedback,
  RiskProfileQuestionnaireSubmitReqDto,
} from '@pimy-b2cweb/apiclient-customer-stmt'
import { DelistingBaseApiConfig, apiClient } from './api-config'
import { useSelector } from 'react-redux'
import { selectProfileSessionState } from '@/stores/profile.selectors'

export const DelistingApi = new EpfDelistedApi(
  DelistingBaseApiConfig,
  apiClient
)

export const useQueryFundList = () => {
  return useQuery({
    queryFn: async () => {
      const res = await DelistingApi.queryFundList()
      return res.data.funds
    },
    queryKey: ['get-funds'],
  })
}

export interface UseMutationSubmitFeedback extends EpfDelistedFundFeedback {}
export const useMutationSubmitFeedback = () => {
  return useMutation({
    mutationFn: async (data: UseMutationSubmitFeedback) => {
      const res = await DelistingApi.feedback({
        epfDelistedFundReqBodyPayload: {
          feedback: {
            ...data,
          },
        },
      })
      return res.data
    },
    mutationKey: ['submit-feedback'],
  })
}

export interface UseMutationSubmitPreference
  extends Omit<EpfDelistedFundConsent, 'agree'> {}

export const useMutationSubmitPreference = () => {
  return useMutation({
    mutationFn: async (data: UseMutationSubmitPreference) => {
      const res = await DelistingApi.consentConfirm({
        epfDelistedFundReqBodyPayload: {
          consent: {
            ...data,
            agree: true,
          },
        },
      })
      return res.data
    },
    mutationKey: ['submit-preference'],
  })
}

export const useQueryGetCurrentQuestionnaire = () => {
  return useQuery({
    queryFn: async () => {
      const res = await DelistingApi.queryIsafQuestionnaire()
      return res.data.questionnaire
    },
    queryKey: ['get-isaf-questionnaire'],
  })
}

export const useMutationSubmitQuestionnaire = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data: RiskProfileQuestionnaireSubmitReqDto) => {
      const res = await DelistingApi.submitIsafQuestionnaire({
        riskProfileQuestionnaireSubmitReqDto: { ...data },
      })
      return res.data
    },
    mutationKey: ['submit-isaf-questionnaire'],
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['isaf-score'],
        refetchType: 'all',
      })
    },
  })
}

export const useQueryIsafScore = () => {
  const { idNo, idType } = useSelector(selectProfileSessionState)
  return useQuery({
    queryFn: async () => {
      const res = await DelistingApi.queryIsafScore({
        riskProfileReqBodyPayload: {
          idNo,
          idType,
        },
      })
      return res.data
    },
    queryKey: ['isaf-score', idNo, idType],
  })
}
