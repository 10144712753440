import {
  DELISTING_ACC_NO,
  DELISTING_RECOMMANDED_FUND,
} from '@/components/InitSearchParams'
import { sessionReset } from '@/stores/delisting'
import { removeSessionStorage } from '@/utils'
import { useDispatch } from 'react-redux'

export const useResetDelistingSession = () => {
  const dispatch = useDispatch()

  const resetDelistingSession = () => {
    dispatch(sessionReset())
    removeSessionStorage(DELISTING_ACC_NO)
    removeSessionStorage(DELISTING_RECOMMANDED_FUND)
  }

  return { resetDelistingSession }
}
