import { RootState } from '.'

export const selectDelistingSessionState = (rootState: RootState) =>
  rootState.delistingSession

export const selectDelistingRecommondedFund = (rootState: RootState) =>
  selectDelistingSessionState(rootState).recommendedFundCode

export const selectDelistingAccountNo = (rootState: RootState) =>
  selectDelistingSessionState(rootState).accountNo

export const selectDelistingSelectedFund = (rootState: RootState) =>
  selectDelistingSessionState(rootState).selectedFundCode
