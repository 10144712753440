import useSearchParams from '@/hooks/useSearchParams'
import { setDelistingSession } from '@/stores/delisting'
import { getSessionStorage, setSessionStorage } from '@/utils'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const DELISTING_ACC_NO = 'acc'
export const DELISTING_RECOMMANDED_FUND = 'fund'

const InitSearchParams = () => {
  const { searchParams, removeSearchParams } = useSearchParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const accountNo = searchParams.get(DELISTING_ACC_NO)
    const recommendedFundCode = searchParams.get(DELISTING_RECOMMANDED_FUND)

    console.log('accountNo: ', accountNo)
    console.log('recommandedFundCode: ', recommendedFundCode)

    if (!!accountNo && !!recommendedFundCode) {
      // decodeAndManageToken(delistingRefNo)
      dispatch(
        setDelistingSession({
          accountNo,
          recommendedFundCode,
        })
      )
      setSessionStorage(DELISTING_ACC_NO, accountNo)
      setSessionStorage(DELISTING_RECOMMANDED_FUND, recommendedFundCode)
      removeSearchParams()
    } else {
      const sessionStorageAccNo = getSessionStorage(DELISTING_ACC_NO)
      const sessionStorageRecommendedFund = getSessionStorage(
        DELISTING_RECOMMANDED_FUND
      )
      !!sessionStorageAccNo &&
        !!sessionStorageRecommendedFund &&
        dispatch(
          setDelistingSession({
            accountNo: sessionStorageAccNo,
            recommendedFundCode: sessionStorageRecommendedFund,
          })
        )
      /* !!sessionStorage &&
        decodeAndManageToken(getSessionStorage(DELISTING_REF_NO)) */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default InitSearchParams
