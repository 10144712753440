import { Button, CircularProgress } from '@mui/material'
import { StmtRecentResBodyPayloadStatementsInner as StatementItem } from '@pimy-b2cweb/apiclient-customer-stmt'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  StatementTypeCategory,
  CATEGORIZED_STATEMENT_TYPES,
} from '@pimy-b2cweb/common'
import { ButtonWithLoading } from '@pimy-b2cweb/frontend-lib'
import { useQueryStmtRecent } from '@/api/'
import { authSessionSlice } from '@/stores/auth'
import { ReactComponent as DownloadIco } from './assets/download.svg'
import { ReactComponent as StatementIco } from './assets/statement.svg'
import { getErrorResponseCode } from '@/utils'
import useNotifications from '@/hooks/useNotifications/'

dayjs.extend(utc)
const { setIsUserIdle } = authSessionSlice.actions

interface convertedStatementItemProps {
  month: string
  items: {
    dateStr: string
    items: StatementItem[]
  }[]
}

const NUM_PER_BETCH = 10

const getCategorizedStatements = (
  data: StatementItem[],
  selectedCategory: StatementTypeCategory.UT | StatementTypeCategory.PRS
) => {
  const categorizedStatementTypes: string[] =
    CATEGORIZED_STATEMENT_TYPES[selectedCategory]
  return [
    ...data.filter((item) =>
      categorizedStatementTypes.includes(item.statementType)
    ),
  ]
}

export const Statements = () => {
  const { t } = useTranslation([
    'dashboardPage',
    'months',
    'statementTypeCategories',
    'statementTypes',
  ])

  const dispatch = useDispatch()
  const [itemsNum, setItemsNum] = useState(NUM_PER_BETCH)
  const [selectedCategory, setSelectedCategory] = useState(
    StatementTypeCategory.All
  )

  const { data = [], status, error } = useQueryStmtRecent()
  const { addNetworkErrorNotice } = useNotifications()
  useEffect(() => {
    if (status === 'error') {
      const errMsg = getErrorResponseCode(error)
      console.error(errMsg)
      addNetworkErrorNotice()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data, error])

  const statements = useMemo(
    () =>
      selectedCategory === StatementTypeCategory.All
        ? data
        : getCategorizedStatements(data, selectedCategory),
    [data, selectedCategory]
  )

  const _statements = convertStatement(statements || [], itemsNum)
  return (
    <>
      <div className='mb-4'>
        <div className='w-full mb-6 h-10 flex'>
          {Object.values(StatementTypeCategory).map((category) => (
            <Button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`flex-1 flex justify-center items-center rounded-full border-solid border-2 mr-2 last:mr-0 ${
                selectedCategory === category
                  ? 'border-pi-principal-blue bg-pi-sky-blue-1'
                  : 'border-pi-gray-2'
              }`}
            >
              <span
                className={`font-bold ${
                  selectedCategory === category
                    ? 'text-pi-principal-blue'
                    : 'text-pi-gray-1'
                }`}
              >
                {t(category || '', { ns: 'statementTypeCategories' })}
              </span>
            </Button>
          ))}
        </div>
        {status === 'loading' ? (
          <div className='w-full h-[10vh] m-0 flex justify-center items-center'>
            <CircularProgress className='text-pi-principal-blue' />
          </div>
        ) : !_statements.length ? (
          <span className='h-[10vh]'>{t('No item found')}</span>
        ) : (
          _statements.map(({ month, items: dItems }, mIdx) => (
            <div key={`${month}-${mIdx}`} className='mb-2'>
              <span className='flex gap-4 items-center mb-5 font-bold'>
                <StatementIco />
                {t(month, { ns: 'months' })}
              </span>
              {dItems.map(({ dateStr, items: sItems }, dIdx) => (
                <div
                  key={`${month}-${mIdx}-${dateStr}-${dIdx}`}
                  className='mb-2'
                >
                  <div className='text-sm leading-[22px] mb-2'>{dateStr}</div>
                  {sItems.map(({ statementType, statementUrl }, sIdx) => (
                    <Button
                      key={`${month}-${mIdx}-${dateStr}-${dIdx}-${statementType}-${sIdx}`}
                      endIcon={<DownloadIco />}
                      className='w-full bg-pi-sky-blue-1 hover:bg-pi-sky-blue-2 py-3 px-4 mb-2'
                      onClick={() => {
                        dispatch(setIsUserIdle())
                        console.log('url=%s', statementUrl)
                        if (!!statementUrl) {
                          window.open(statementUrl, '_blank')
                        }
                      }}
                    >
                      <span className='grow text-left font-normal text-pi-dark-prussian-blue'>
                        {t(statementType || '', { ns: 'statementTypes' })}
                      </span>
                    </Button>
                  ))}
                </div>
              ))}
            </div>
          ))
        )}
      </div>
      <ButtonWithLoading
        variant='outlined'
        size='large'
        className='w-full'
        onClick={() => {
          dispatch(setIsUserIdle())
          setItemsNum(itemsNum + NUM_PER_BETCH)
        }}
        disabled={itemsNum > (statements || []).length}
      >
        {t('Load more statement')}
      </ButtonWithLoading>
    </>
  )
}

const convertStatement = (statements: StatementItem[], itemsNum: number) => {
  let _statements: convertedStatementItemProps[] = []

  statements.slice(0, itemsNum).forEach((sItem) => {
    const date = dayjs.utc(sItem.statementDate).local()
    const month = date.format('MMMM')
    const dateStr = date.format('DD MMM YYYY')

    const _currMonth = _statements.find((mItem) => mItem.month === month)
    if (!_currMonth) {
      //append new month to _statements
      _statements.push({
        month,
        items: [
          {
            dateStr,
            items: [sItem],
          },
        ],
      })
    } else {
      const _currDate = _currMonth.items.find(
        (dItem) => dItem.dateStr === dateStr
      )
      if (!_currDate) {
        //append new date to current month
        _currMonth.items.push({
          dateStr,
          items: [sItem],
        })
      } else {
        //append new item to current date
        _currDate.items.push(sItem)
      }
    }
  })

  return _statements
}

export default Statements
