import { Rating, TextareaAutosize, TextField } from '@mui/material'
import {
  ButtonWithLoading,
  FormErrorMessage,
  FormLabel,
  ResponseError,
  TelTextField,
  useModal,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'

import { ReactComponent as StarFillIco } from './assets/Star-fill.svg'
import { ReactComponent as StarIco } from './assets/Star.svg'
import { Controller, useForm } from 'react-hook-form'
import { getErrorResponseCode, isNumber } from '@/utils'
import {
  UseMutationSubmitFeedback,
  useMutationSubmitFeedback,
  useQueryIsafScore,
} from '@/api'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectDelistingAccountNo } from '@/stores/delisting.selectors'
import HomePageRedirectModal from './components/HomePageRedirectModal'
import LoadingPage from '@/pages/Loading'

export interface FeedbackFormProps {
  email: string
  mobilePhoneNo: string
  rating: number
  comment: string
}

const Feedback = () => {
  const { t } = useTranslation('feedbackPage')
  const { data: isafData, isLoading: isLoadingIsafQuery } = useQueryIsafScore()

  const { open, modalOpen } = useModal()

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const {
    mutate,
    data: mutatedData,
    error: mutatedError,
    status: mutateStatus,
    isLoading: mutateIsLoading,
  } = useMutationSubmitFeedback()

  const { control, handleSubmit, setValue } = useForm<FeedbackFormProps>({
    defaultValues: {
      email: '',
      mobilePhoneNo: '',
      rating: undefined,
      comment: '',
    },
  })

  useEffect(() => {
    if (!!isafData) {
      setValue('email', isafData?.emailAddress || '')
      setValue('mobilePhoneNo', isafData?.mobileNo || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isafData])

  const accountNo = useSelector(selectDelistingAccountNo)

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs(errMsg)
    }
    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log(mutatedData)
      modalOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateStatus, mutatedData, mutatedError])

  const onSubmit = (data: FeedbackFormProps) => {
    console.log('FeedbackFormProps: ', data)
    const { rating, comment, email, mobilePhoneNo } = data
    const mutatingData: UseMutationSubmitFeedback = {
      id: accountNo,
      rating: Number(rating),
      comment,
      emailAddress: email,
      mobileNo: mobilePhoneNo,
    }
    mutate(mutatingData)
  }

  return (
    <>
      {!!isLoadingIsafQuery ? (
        <LoadingPage />
      ) : (
        <>
          <h1>{t('thank-you')}</h1>
          <p>{t('please-update-your-contact-info')}</p>
          {!!responseErrorAttrs && (
            <ResponseError>
              {t(responseErrorAttrs.i18nKey, {
                ns: responseErrorAttrs.ns,
              })}
            </ResponseError>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              /* rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }} */
              render={({ field, fieldState: { error } }) => (
                <FormLabel id='email' label={t('email')} isError={!!error}>
                  <TextField
                    type='email'
                    fullWidth
                    {...field}
                    error={!!error}
                    helperText={!!error ? (error?.message as string) : ''}
                    disabled={mutateIsLoading}
                    autoComplete='off'
                  />
                </FormLabel>
              )}
            />
            <Controller
              name='mobilePhoneNo'
              control={control}
              defaultValue=''
              rules={{
                /* required: {
              value: true,
              message: 'Required',
            }, */
                validate: (val: string) => {
                  if (!isNumber(val)) {
                    return t('invalid-input', { ns: 'errorResponse' })
                  }
                  return true
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormLabel
                  id='mobilePhoneNo'
                  label={t('contact-number')}
                  isError={!!error}
                  className='!mb-10'
                >
                  <TelTextField
                    fullWidth
                    {...field}
                    error={!!error}
                    helperText={!!error ? (error?.message as string) : ''}
                    disabled={mutateIsLoading}
                  />
                </FormLabel>
              )}
            />
            <Controller
              name='rating'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormLabel
                  id='rating'
                  label={t('how-would-you-rate-your-exp')}
                  required
                  isError={!!error}
                >
                  <Rating
                    icon={<StarFillIco className='w-10 h-10' />}
                    emptyIcon={<StarIco className='w-10 h-10' />}
                    precision={1}
                    disabled={mutateIsLoading}
                    {...field}
                  />
                  <FormErrorMessage error={error} />
                </FormLabel>
              )}
            />
            <Controller
              name='comment'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormLabel
                  id='comment'
                  label={t('please-share-your-feedback')}
                  isError={!!error}
                  className='!mb-10'
                >
                  <TextareaAutosize
                    className={`border rounded border-solid border-pi-gray-1 px-4 py-3 !text-base font-sans ${
                      !!error ? '!border-pi-utility-red' : ''
                    } w-full resize-none`}
                    minRows={5}
                    maxRows={5}
                    placeholder={t('what-we-can-improve')}
                    disabled={mutateIsLoading}
                    {...field}
                  />
                  <FormErrorMessage error={error} />
                </FormLabel>
              )}
            />

            <ButtonWithLoading
              type='submit'
              fullWidth
              variant='contained'
              size='large'
              className='mb-10'
              disabled={mutateIsLoading}
              isLoading={mutateIsLoading}
            >
              {t('submit-feedback-and-contact-info')}
            </ButtonWithLoading>
          </form>
          <HomePageRedirectModal open={open} />
        </>
      )}
    </>
  )
}

export default Feedback
