import Logo from '@pimy-b2cweb/frontend-lib/libSrc/assets/logo-cimb.svg'

export const MainLogo = () => (
  <img
    src={Logo}
    className='main-logo block w-[128px] h-auto mb-6'
    alt='Principal in alliance with CIMB'
  />
)

export default MainLogo
