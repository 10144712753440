import { OtpInitReqBodyPayloadIdTypeEnum as idTypeEnum } from '@pimy-b2cweb/apiclient-customer-auth'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DelistingTokenDecoded {
  idtype: idTypeEnum
  idNo: string
  delistedFundCode: string
  recommendedFundCode: string
  selectedFundCode: string
}

export interface DelistingSessionState {
  recommendedFundCode: string
  accountNo: string
  selectedFundCode?: string
}

const initialState: DelistingSessionState = {
  recommendedFundCode: '',
  accountNo: '',
}

export const delistingSessionSlice = createSlice({
  name: 'delistingSession',
  initialState,
  reducers: {
    sessionReset: (state) => {
      state.recommendedFundCode = initialState.recommendedFundCode
      state.accountNo = initialState.accountNo
    },
    setDelistingSession: (
      state,
      action: PayloadAction<DelistingSessionState>
    ) => {
      state.recommendedFundCode = action.payload.recommendedFundCode
      state.accountNo = action.payload.accountNo
    },
    setDelistingSelectedFund: (
      state,
      action: PayloadAction<
        Omit<DelistingSessionState, 'accountNo' | 'recommendedFundCode'>
      >
    ) => {
      state.selectedFundCode = action.payload.selectedFundCode
    },
  },
})

// action export
export const { sessionReset, setDelistingSession, setDelistingSelectedFund } =
  delistingSessionSlice.actions

export default delistingSessionSlice.reducer
