import { IconButton } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import dayjs from 'dayjs'
import { useSnackbar, VariantType, SnackbarMessage } from 'notistack'

export interface AddNotificationPorps {
  key?: string
  message: SnackbarMessage
  variant?: VariantType
  persist?: boolean
}

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const addNotice = ({
    message,
    key = dayjs().toISOString(),
    variant = 'info',
    persist = false,
  }: AddNotificationPorps) => {
    enqueueSnackbar(message, {
      key,
      variant,
      persist,
      action: (
        <IconButton onClick={() => closeSnackbar(key)}>
          <HighlightOffIcon className='text-white' />
        </IconButton>
      ),
    })
  }

  const addValidateErrorNotice = () => {
    addNotice({
      message: 'Validation Error, please review your inputs.',
      variant: 'error',
    })
  }

  const addSubmitSucceedNotice = () => {
    addNotice({
      message: 'Submisison succeed.',
      variant: 'success',
    })
  }

  const addNetworkErrorNotice = () => {
    addNotice({
      message: 'Network error, please try again later.',
      variant: 'error',
    })
  }

  const dismissNotice = (key: string) => closeSnackbar(key)
  const dismissAllNotice = () => closeSnackbar()

  return {
    addNotice,
    addNetworkErrorNotice,
    addValidateErrorNotice,
    addSubmitSucceedNotice,
    dismissNotice,
    dismissAllNotice,
  }
}

export default useNotifications
