import { useMutationOtpResend } from '@/api'
import { store } from '@/stores'
import { authSessionSlice } from '@/stores/auth'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { useCallback, useEffect, useState } from 'react'
import useCountDown from 'react-countdown-hook'
import { useDispatch } from 'react-redux'
import { getErrorResponseCode, ErrorResponseCodeEnum } from '@/utils'

const initialTime = 2 * 60 * 1000
const interval = 1000 // interval to change remaining time amount, defaults to 1000
const { renewChallengeId } = authSessionSlice.actions

export const useResendSms = (initCanResend: boolean) => {
  let { challengeId: lastChallengeId } =
    selectAuthSessionState(store.getState()) ?? {}

  const [
    timeLeft,
    {
      start,
      // pause, resume, reset
    },
  ] = useCountDown(initialTime, interval)

  const [resending, setResending] = useState(false)
  const [resendError, setResendError] = useState<string | undefined>(undefined)

  const {
    mutate,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationOtpResend()

  const startCountDown = useCallback(() => {
    start(initialTime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onResendOtp = useCallback(() => {
    if (!resending) {
      if (!!lastChallengeId) {
        setResending(true)
        mutate({ lastChallengeId })
        return
      }
      console.error('missing lastChallengeId')
    } else {
      console.log('resending')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch()
  useEffect(() => {
    if (!!initCanResend) startCountDown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let _lockResend = false
    if (!initCanResend) _lockResend = true

    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResendError(errMsg)
      return
    }

    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(renewChallengeId({ challengeId: mutatedData.challengeId }))
      if (mutatedData.canResend === false) {
        _lockResend = true
      } else {
        startCountDown()
      }
    }
    setResendError(
      !!_lockResend ? ErrorResponseCodeEnum.CanNotResendOtp : undefined
    )

    if (mutateStatus !== 'loading') {
      setResending(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateStatus, mutatedData, mutatedError, initCanResend])

  return { timeLeft, resendError, resending, onResendOtp }
}

export default useResendSms
