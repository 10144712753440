import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ProfileSessionState } from '@/stores/profile'
import { ReactComponent as ContactNoIco } from '../../assets/contactNo.svg'
import { ReactComponent as IdNoIco } from '../../assets/idNo.svg'
import { ReactComponent as NameIco } from '../../assets/name.svg'

export const Profile = ({
  fullName,
  // idNo,
  phoneNumber,
}: ProfileSessionState) => {
  const { t } = useTranslation('common')
  return (
    <>
      <ProfileItem iconName='name' title={t('Name')} info={fullName} />
      {/* <ProfileItem iconName='inNo' title={t('ID Number')} info={idNo} /> */}
      <ProfileItem
        iconName='contactNo'
        title={t('Contact Number')}
        info={phoneNumber}
        className='sm:mb-0'
      />
    </>
  )
}

const ProfileItem = ({
  iconName,
  title,
  info,
  className = '',
}: {
  iconName: 'name' | 'inNo' | 'contactNo'
  title: string
  info: string
  className?: string
}) => (
  <div className={`flex gap-4 items-center mb-4 sm:mb-2 ${className}`}>
    {iconName === 'name' ? (
      <NameIco className='sm:hidden' />
    ) : iconName === 'inNo' ? (
      <IdNoIco className='sm:hidden' />
    ) : (
      <ContactNoIco className='sm:hidden' />
    )}
    <div className='flex flex-col'>
      <span className='font-bold text-sm leading-[22px]'>{title}</span>
      <span>{info}</span>
    </div>
  </div>
)

export default connect((state: any) => ({
  fullName: state.profileSession.fullName,
  idNo: state.profileSession.idNo,
  phoneNumber: state.profileSession.phoneNumber,
}))(Profile)
