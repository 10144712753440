import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutationExtendToken } from '@/api/session'
import useNotifications from '@/hooks/useNotifications/'
import { authSessionSlice } from '@/stores/auth'
import { getErrorResponseCode } from '@/utils'

const { renewToken } = authSessionSlice.actions

const useExtToken = ({
  onLogout,
  modalClose,
}: {
  onLogout: () => void
  modalClose: () => void
}) => {
  const { addNetworkErrorNotice } = useNotifications()
  const dispatch = useDispatch()

  const {
    mutate: onExtSession,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationExtendToken()

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      console.error(errMsg)
      addNetworkErrorNotice()
      onLogout()
      return
    }

    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(
        renewToken({
          token: mutatedData.token,
          expiredIn: mutatedData.expiredIn,
        })
      )
      modalClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateStatus, mutatedData, mutatedError])

  return { onExtSession, mutateStatus, mutatedData, mutatedError }
}

export default useExtToken
