import { useSelector } from 'react-redux'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { StatusEnum } from '@/config'
import { useEffect, useState } from 'react'
import useCountDown from 'react-countdown-hook'

const interval = 1000 // interval to change

export const useSessionCountdown = () => {
  const { status, token, expiredIn } = useSelector(selectAuthSessionState)
  const [initialized, setInitialized] = useState(false)
  const [
    timeleft,
    {
      start,
      // pause,
      // resume,
      reset,
    },
  ] = useCountDown(Number(expiredIn || 0), interval)

  useEffect(() => {
    if (status === StatusEnum.LoggedIn) {
      start()
      setInitialized(true)
    } else {
      reset()
      setInitialized(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return { timeleft: !initialized ? null : timeleft }
}

export default useSessionCountdown
