import { RootState } from './index'

export const selectAuthSessionState = (rootState: RootState) =>
  rootState.authSession

export const selectAuthStatus = (rootState: RootState) =>
  selectAuthSessionState(rootState).status

export const selectAuthToken = (rootState: RootState) =>
  selectAuthSessionState(rootState).token

export const selectExpiredIn = (rootState: RootState) =>
  selectAuthSessionState(rootState).expiredIn
