import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SnackbarProvider } from 'notistack'
import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import {
  Footer as FooterUi,
  FOOTER_URL_PREFIX,
} from '@pimy-b2cweb/frontend-lib'
import MainBody from '@/layout/MainBody'
import { queryClient } from '@/lib/react-query'
import { store } from '@/stores'

function App() {
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage('en')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider
              maxSnack={10}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <CssBaseline />
              <MainBody />
              <Footer />
            </SnackbarProvider>
          </LocalizationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  )
}

const FOOTER_LINKS = [
  {
    hrefPart:
      'others/terms-and-conditions?_ga=2.132971278.1896652433.1690343445-1267269838.1616149641',
    labelKey: 'Terms and conditions',
  },
  {
    hrefPart:
      'privacy-notice-my?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641',
    labelKey: 'Privacy notice',
  },
  {
    hrefPart:
      'internet-risk-my?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641',
    labelKey: 'Internet risk',
  },
  {
    hrefPart:
      'others/transaction-notice?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641',
    labelKey: 'Transaction notice',
  },
  {
    hrefPart:
      'cross-trades-policy-my?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641',
    labelKey: 'Cross trade products',
  },
  {
    hrefPart:
      'en/epf-faq?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641',
    labelKey: 'FAQ',
  },
  {
    hrefPart:
      'en/contact-us?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641',
    labelKey: 'Contact us',
  },
]

const Footer = () => {
  const { t } = useTranslation('footer')

  return (
    <FooterUi
      urlPrefix={FOOTER_URL_PREFIX}
      links={FOOTER_LINKS.map(({ hrefPart, labelKey }) => ({
        hrefPart,
        label: t(labelKey),
      }))}
      text={{ regulatedByScm: t('regulated-by-scm') }}
    />
  )
}

export default App
