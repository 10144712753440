import DelistingLayout from '@/layout/DelistingLayout'
import { useState } from 'react'
import { connect } from 'react-redux'
import { StatusEnum } from '@/config'
import Feedback from './subpages/Feedback'
import FundSelection from './subpages/FundSelection'
import ISAF from './subpages/ISAF'
import OtpVerifyPage from './subpages/OtpVerify'

enum DelistingPageState {
  FUND_SELECTION = 'fund_selection',
  FEEDBACK = 'feedback',
  ISAF = 'isaf',
}
const Delisting = connect((state: any) => ({
  status: state.authSession.status,
}))(({ status }: { status: StatusEnum }) => {
  const [pageState, setPageState] = useState(DelistingPageState.FUND_SELECTION)

  const goToIsaf = () => setPageState(DelistingPageState.ISAF)
  const goToFeedback = () => setPageState(DelistingPageState.FEEDBACK)
  const goBackToFundSelection = () =>
    setPageState(DelistingPageState.FUND_SELECTION)

  return (
    <DelistingLayout>
      {status === StatusEnum.FundDelistingOtp ? (
        <OtpVerifyPage goToFeedback={goToFeedback} />
      ) : pageState === DelistingPageState.FEEDBACK ? (
        <Feedback />
      ) : pageState === DelistingPageState.ISAF ? (
        <ISAF goBackToFundSelection={goBackToFundSelection} />
      ) : (
        <FundSelection goToIsaf={goToIsaf} />
      )}
    </DelistingLayout>
  )
})
export default Delisting
