import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionModal } from '@pimy-b2cweb/frontend-lib'
import useManageSession from './useManageSession/'

export const SessionModal = memo(() => {
  const { t } = useTranslation(['dashboardPage', 'common'])
  const { mmss, mutateStatus, onLogout, onExtSession, ...rest } =
    useManageSession()

  return (
    <ActionModal
      title={t('login-about-expire-title')}
      actions={[
        {
          label: t('Cancel', { ns: 'common' }),
          onClick: onLogout,
          disabled: mutateStatus === 'loading',
        },
        {
          label: t('OK', { ns: 'common' }),
          onClick: onExtSession,
          disabled: mutateStatus === 'loading',
        },
      ]}
      {...rest}
    >
      <p>{!!mmss && t('login-about-expire-body', { mmss })}</p>
    </ActionModal>
  )
})
export default SessionModal
