import { useQuery } from '@tanstack/react-query'
import { StatementApi as StatementApiClass } from '@pimy-b2cweb/apiclient-customer-stmt'
import { BaseApiConfig, apiClient } from './api-config'

import { store } from '@/stores'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { StatusEnum } from '@/config'

export const StmtApi = new StatementApiClass(BaseApiConfig, apiClient)

export const useQueryStmtRecent = () => {
  let { token, status } = selectAuthSessionState(store.getState()) ?? {}
  return useQuery({
    queryKey: ['query-stmt-recent', token, status],
    queryFn: async () => {
      const {
        data: {
          body: { statements },
        },
      } = await StmtApi.recent({
        stmtRecentReqBodyPayload: {
          accountNumbers: [],
        },
      })
      return statements
    },
    enabled: !!token && status === StatusEnum.LoggedIn,
  })
}
